import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import FounderPass from '../views/FounderPass.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/abt',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/founderPass',
    name: 'FounderPasses',
    component: FounderPass
  },


]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

export default router
