<template>
    <section id="banner" class="cont_banner py-3">
        <video class="video_horizontal" src="../videos/hero_ctc_ingles.mp4"  muted playsinline autoplay loop></video>
       <video class="video_vertical" src="../videos/hero_ctc_movil.mp4"  muted playsinline autoplay loop></video>

       <div class="cont_botones">
            <div class="cont_boton text-center m-2 m-xl-4" data-augmented-ui="br-2-clip-x border">
                <router-link class="boton boton_importante align-self-center "  to="/founderPass">{{ $t('banner.button') }}</router-link>
            </div>  
            <div class="cont_boton text-center m-2 m-xl-4" data-augmented-ui="br-2-clip-x border">
                <a class="boton boton_importante align-self-center m-2" href="https://dex-trade.com/spot/trading/CTCUSDT">{{ $t('banner.button2') }}</a>
            </div>  
       </div>

       
        
    </section>
</template>

<script>
export default {
    name: 'Banner',

    data: () => ({
      
    }),
  }
</script>

<style>
        
    /* Section Banner */

    #banner{
        margin-top: 3rem;
        width: 100vw;
        height: 100vh;
        position: relative;
        overflow: hidden;
    }

    #banner video{
        position: absolute;
        width: 100%;
        height: auto;     
    }

    #banner .video_horizontal{
        display: none;
    }

    #banner .cont_botones{
        position: relative;
        top: 40%;
    }

    #banner .cont_boton{
        padding: 0;
    }

    #banner .cont_botones .cont_boton .boton {
        color: var(--blanco);
        display:block;
        width: 100%;
        height: 100%;
        padding: 1rem;
        border: 1px solid red;
    }




</style>