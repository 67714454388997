<template>
  <v-app>
    <Menu />    
    <v-main>      
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import Menu from '../src/components/Menu.vue'
  import axios from "axios";


  export default {
    name: 'Home',
  
    components: {
      Menu,
    },
    methods: {
      inicio(){},
    },
    // created () {
    //  axios.get("https://api.pancakeswap.info/api/v2/tokens/0xc7D495D8BA942d62e69ba5c617A8575beA35717a").then((result) => {
      
    //   this.result = result.data;
    //   // this.name = body.data.name;
    //   // this.price = body.data.price;
    //   // this.priceBnb = body.data.price_BNB;
    //   console.log(result.data[0]);
    //   })
     
    // },
    // data(){
    //   return {
    //       name: null,
    //       price: null,
    //       priceBnb: null,
    //        res: null
    //   }
    // },
  }
</script>

<style>
  /* Colores */
  :root{
      --blanco: #fff;
      --negro: #20061D;
      --rosa: #C549BE;
      --azul-oscuro: #060c21;
      --azul: #213EB8;
      --amarillo-claro: #FAEE08;
      --amarillo: #FDBE05;
      --turquesa: #ABFCFE;
      --rojo: #ff0058;
  }

  /* Fuentes */
  @font-face {
    font-family: "Neonblitz";
    src: local("Neonblitz"),
    url(./fonts/Neonblitz.ttf) format("truetype");
  }

   @font-face {
    font-family: "Horsemen";
    src: local("Horsemen"),
    url(./fonts/Horsemen.otf) format("opentype");
  }

   @font-face {
    font-family: "Hotliner";
    src: local("Hotliner"),
    url(./fonts/Hotliner.otf) format("opentype");
  }

   @font-face {
    font-family: "LagoonBeach";
    src: local("LagoonBeach"),
    url(./fonts/LagoonBeach.otf) format("opentype");
  }
    
  @font-face{
      font-family: 'Night-Light-Square'; 
      src: url(./fonts/night_light/TTF/NightLightSquare.ttf) format('truetype');
  }

  /* Estilos globales */

html{
  width: 100vw;
   margin: 0;
  height: 100%;
  background: var(--azul-oscuro);
  overflow-y: auto;
}
  body{
     background: rgba(black, .2);
  }
 
 *{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    transition: 0.4s ease all;
  }

  a{
      text-decoration: none;
      color: var(--blanco);
  } 

  a:hover{
      cursor: pointer;
  }

  ul {
    padding: 0 !important;
    margin: 0 !important;
  }

  .texto{
     font-family: fira-sans,sans-serif;
      font-weight: 300;
      font-style: normal;
      text-align: justify; 
      font-size: 1rem;
  }

  .subtexto{
      font-family: 'Night-Light-Square'; 
      text-align: justify;
      font-size: 1.5rem;
      font-weight: bold;
  }

  .titulo{
        font-family: "Horsemen";
        letter-spacing: 15px;
        color: var(--amarillo-claro);
  }
  
  .subtitulo{
    font-family: rift,sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
  }

  
body::-webkit-scrollbar {
    width: 1em;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #FDBE05;
  border-radius:1rem;
  outline: none;
}

/* Botones  */
.cont_boton{
    margin: 2rem auto;
    --aug-border-bg: var(--amarillo);
    width: 60%;
    position: relative;
    top: 40%;
}

.boton{ 
    color: var(--blanco);
    font-family: rift,sans-serif !important;
    font-weight: 700;
    font-style: normal;
    padding: 1rem;
    font-size: 1.3rem;
}

.cont_boton:hover{
    background: var(--amarillo);
    cursor: pointer;
    transition: 0.3s;
}

.cont_boton:hover .boton,
.cont_boton .boton .enlace:hover {
    color: var(--azul-oscuro) !important;
}

.boton_importante{
    background: var(--amarillo);
    color: var(--azul-oscuro) !important;
    font-size: 1.3rem !important;
    padding: 1rem;
}

.boton_importante:hover{
  background: var(--turquesa);
}

</style>
