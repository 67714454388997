<template>
    
    <section class="navbar">	
		<div class="logo mx-2">
			<router-link class="logo_menu align-self-center" to="/">
				<a class="enlace">Citycoin</a>
			 </router-link> 
		</div>		
		<div>
			<ul class="links">
				<li  v-on:click="cerrar()" class=" mx-3 "><span id="wallet" class="enlace "></span></li>
				<div  v-on:click="cerrar()" class=" mx-2 "><span class="enlace wallet"><i class="fa-solid fa-wallet mr-2"></i> <span id="balance_wallet" ></span></span></div>
				<li  v-on:click="cerrar()" class=" mx-3 "><a class="enlace" href="https://psahtechnologies.com/" target="_blank"> {{ $t('menu.text1') }}</a></li>
				<!-- <li  v-on:click="cerrar()" class=" mx-3"><router-link class="enlace"   to="/citybot">Citybot</router-link> </li> -->
				<li  v-on:click="cerrar()" class=" mx-3 "><a class="enlace" href="https://albertohortabtc.gitbook.io/citycoin/" target="_blank">{{ $t('menu.text2') }}</a></li>
				<li  v-on:click="cerrar()" class=" mx-3"><router-link class="enlace"   to="/founderPass">{{ $t('menu.text3') }}  </router-link> </li>
				<div class="cont_redes d-flex justify-content-center mx-auto">
					<div  v-on:click="cerrar()"  class=" mx-2 "><a class="enlace"   href="https://www.tiktok.com/@citycoin.ctc" target="_blank"><i class=" icono fa-brands fa-tiktok"></i></a></div>
					<div  v-on:click="cerrar()" class=" mx-2 "><a class="enlace"   href="https://www.instagram.com/citycoin.ctc/" target="_blank"><i class=" icono fa-brands fa-instagram"></i></a></div>
					<div  v-on:click="cerrar()" class=" mx-2 "><a class="enlace"   href="https://t.me/citycoin_ctc" target="_blank"><i class=" icono fa-brands fa-telegram"></i></a></div>
					<div  v-on:click="cerrar()" class=" mx-2 "><a class="enlace"   href="https://twitter.com/CitycoinCtc" target="_blank"><i class=" icono fa-brands fa-twitter"></i></a></div>
					
				</div>
				<li  v-on:click="idiom()" class=" mx-3 enlace">{{ $t('menu.text4') }}</li>
				
			</ul>
		</div>	
		
		<div class="toggle mx-2" v-on:click="abrir()">
			<div class="line1"></div>
			<div class="line2"></div>
			<div class="line3"></div>
		</div>
		
		
	</section>
</template>

<script>
export default {
     name: 'Menu',
		 methods: {
			abrir () {
				const navbar = document.querySelector('.navbar')
				navbar.querySelector('.toggle').addEventListener('click',()=>{          
				navbar.classList.toggle('collapsed')          
				})

				window.addEventListener('scroll',(e)=>{
				let windowY = window.pageYOffset
				let navbarHeight = document.querySelector('.navbar').offsetHeight
				if(windowY>navbarHeight) navbar.classList.add('sticky')
				else navbar.classList.remove('sticky')
				})
			},
			cerrar () {
				const navbar = document.querySelector('.navbar')
				navbar.classList.remove('collapsed')   
			
			},
			idiom(){
				if (this.$i18n.locale === 'es') {
					return this.$i18n.locale = 'en'
				} else{
					return this.$i18n.locale = 'es'
				}
			},
			
    	}
    
}
</script>

<style>

/* Menu */



.navbar {
	 background: var(--negro);
	 color: var(--blanco);
	 padding: 15px;
	 width: 100%;
	 height: 65px;
	 display: flex;
	 align-items: center;
	 position: fixed;
	 top: 0;
	 left: 0;
	 z-index: 3;
	 overflow: hidden;
	 justify-content: space-between;
}
 .navbar .logo {
	 cursor: pointer;
	 font-family: Lacquer;
	 margin-left: 4rem;
}

.cont_redes .icono{
	color: var(--blanco);
}

ul {
	 padding: 0;
	 margin: 0;
}

 .navbar ul.links {
	 /* margin-left: auto; */
	 margin-right: 50px;
	 list-style: none;
	 display: flex;
	 justify-content:right;
	 /* align-items: right; */
	 transition: 0.4s ease all, 0s ease background;
}

.enlace {
	 text-decoration: none;
	 color: var(--blanco) !important;
	 text-transform: uppercase;
	 padding: 0 15px;
	 height: 65px;
	 display: flex;
	 align-items: center;
	 transition: 0.4s ease all, 0s ease margin;
}

.enlace:hover,
.icono:hover {
	 color: #ffc600 !important;
	 cursor: pointer;
}

.wallet{
    color: var(--amarillo) !important;
	font-weight: bold;
}

.wallet:hover{
    color: var(--rosa) !important;
}


.navbar.sticky {
	 position: fixed;
	 z-index: 40;
}
 .navbar.sticky ~ main {
	 position: relative;
	 z-index: 20;
	 top: 65px;
}

 @media screen and (max-width: 1024px) {
	 .navbar .right {
		 height: 0;
		 overflow: hidden;
	}
	 .toggle {
		 position: absolute;
		 top: 32.5px;
		 right: 20px;
		 z-index: 99;
		 transform: translateY(-50%);
		 cursor: pointer;
		 width: 40px;
		 height: 40px;
		 display: flex;
		 flex-direction: column;
		 justify-content: center;
		 align-items: center;
		 overflow: hidden;
	}
	 .toggle > * {
		 width: 80%;
		 height: 2px;
		 background: var(--blanco);
		 margin: 3px 0;
	}
	 .navbar.collapsed .toggle .line1 {
		 transform: rotate(-45deg) translate(-4px, 5px);
	}
	 .navbar.collapsed .toggle .line2 {
		 opacity: 0;
	}
	 .navbar.collapsed .toggle .line3 {
		 transform: rotate(45deg) translate(-5px, -6px);
	}
	 .navbar.collapsed .logo {
		 position: absolute;
		 z-index: 44;
	}
	 .navbar .links {
		 position: fixed;
		 background: var(--negro);
		 z-index: 40;
		 top: 0;
		 left: -100%;
		 flex-direction: column;
		 width: 100vw;
		 height: 100vh;
	}
	 .navbar .links li:first-child {
		 margin-top: 6rem;
	}
	 .navbar.collapsed .links {
		 left: 0;
	}
	 .enlace {
		 text-align: left;
	}

	.wallet{
		word-break: break-all;
		margin-right: 1rem;
	}

	.cont_redes{
		margin-top: 4rem;
	}

	.cont_redes .enlace .icono{
		font-size: 1.5rem;
	}

	 .navbar.collapsed .links li {
		 width: 100%;
	}
	 .enlace:hover {
		 color: var(--amarillo);
	}
	 .navbar.collapsed .right {
		 position: fixed;
		 width: 45vw;
		 text-align: center;
		 height: auto;
		 bottom: 40px;
		 left: 22.5vw;
		 transform: translateX(-50%);
		 z-index: 45;
	}
	 .navbar.collapsed ~ main {
		 filter: blur(1px);
		 opacity: 0.8;
	}
	 .navbar.sticky {
		 position: fixed;
		 z-index: 40;
	}
}
</style>