import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from "axios";
import '../public/css/style.css'
import '../public/css/responsive.css'
import { store } from './store/'
import VueI18n from 'vue-i18n'
import MinAbi from './abis/MinAbi.json'
import Pago from './abis/Pago.json'


Vue.use(VueI18n)

// import { ethers } from './ethers/'

const messages = {
  
    en: {
      menu:{
        text1: "Citybot",
        text2: "Whitepaper",
        text3: "Founder Passes",
        text4: "ES",
      },
      banner:{
        button:"See Passes",
        button2:"Buy CTC in Dex"
      },
      info:{
        title:"CityCoin is a digital token supported in real projects",
        title2:"Now you can buy CTC in Pancakeswap",
        title3:"Pre-sale",
        text1:"CityCoin was born with the firm intention of transforming digital earnings into tangible ones, profits that can be used by you in your day-to-day life in the city where you are",
        text2:"Add Citycoin to your",
        text3:"Name",
        text4:"Citycoin",
        text5:"Contract",
        text6:"0xc7D495D8BA942d62e69ba5c617A8575beA35717a",
        text7:"Symbol",
        text8:"CTC",
        text9:"Decimals",
        text10:"18",
        text11:"Chain ID",
        text12:"56",
        text13:"Type the amount of CTC to buy in",
        text14:"My Wallet",
        text15:"CTC Total Price in BUSD",
        text16:"Download the Trust Wallet App in your mobile device and follow the next steps:",
        button:"Binance Smart Contract",
        button2:"Buy",
      },
      whitelist:{
  
      },
      pilares:{
        title: "Crypto for Real Business",
        text1:"Real Estate",
        text2:"Trading Bot",
        text3:"Cars",
        text4:"Technology",
        text5:"Mining",
      },
      citybot:{
        title:"Citybot",
        text1:"Generate profit from anywhere with our algorithm.",
        text2:"Easy, secure and Hakuna Matata",
        button:"Invest Here",
      },
      tokenomics:{
        title: "TOKENOMICS | ",
        text1:"CityCoin is made up of 21,000,000 tokens issued on the Binance network (BEP20), which will be focused on the development of projects chosen by the community itself, this involves; technology development, mining, real estate projects, etc. To achieve this, it will be distributed as follows:",
        text2:"NFT Owners Token Pre-sale",
        text3:"Token Open Sale",
        text4:"Token DEX Sale",
        text5:"Token Stake",
        text6:"Real Estate",
        text7:"Marketing",
        text8:"Team",
        text9:"Finance and Legal",
        text10:"Mining Hardware",
        text11:"Technology Development",
      },
      roadmap:{
        title:"Roadmap"
      },
      team:{
        title:"What makes Citycoin strong is the team that supports it.",
        text1:"Alberto Horta",
        text2:"Developer & Trader",
        text3:"Raul Romo",
        text4:"Stock Market Analyst",
        text5:"Erik Real",
        text6:"Expert in Projects and Technology",
        text7:"Pedro Sánchez Barrio",
        text8:"Investment Strategy Analyst",
        text9:"Dr. Abraham Jacques",
        text10:"'Gauss-Jacques' Method Author",
      },
      founderPass:{
        title:"Pre-sale now available",
        button:"Buy Here",
        button2:"See More",
        text1:"Silver",
        text2:"Palladium",
        text3:"Gold",
        text4:"Includes",
        text5:"-Access to the pre-sale of the crypto Citycoin",
        text6:"-Access to the pre-sale of the crypto Citycoin",
        text7:"-10% of the profit of the BOTS",
        text8:"-Governance votes with power x20",
        text9:"-Access to exclusive VIP events for palladium members",
        text10:"-Access to pre-sale of lots, land, real estate and cars",
        text11:"-Access to the pre-sale of the crypto Citycoin",
        text12:"-Access to trading bot for life",
        text13:"-Governance votes with power x10"
      }
    },
    es: {
      menu:{
        text1: "Citybot",
        text2: "Whitepaper",
        text3: "Pases de Fundador",
        text4: "EN",
      },
      banner:{
        button:"Ver Pases",
        button2:"Comprar CTC en Dex"
      },
      info:{
        title:"CityCoin es un token digital respaldado en proyectos reales",
        title2:"Ahora puedes comprar CTC en Pancakeswap",
        title3:"Preventa",
        text1:"CityCoin nace con la firme intención de transformar las ganancias digitales en tangibles, ganancias aprovechables por tí en tú día a día en la ciudad donde te encuentres.",
        text2:"Agrega Citycoin a tu",
        text3:"Nombre:",
        text4:"Citycoin",
        text5:"Contrato:",
        text6:"0xc7D495D8BA942d62e69ba5c617A8575beA35717a",
        text7:"Símbolo:",
        text8:"CTC",
        text9:"Decimales:",
        text10:"18",
        text11:"Chain ID:",
        text12:"56",
        text13:"Ingrese el monto de CTC a comprar a",
        text14:"Mi Wallet",
        text15:"Precio Total de CTC en BUSD",
        text16:"Descarga la aplicación de Trust Wallet en tu dispositivo móvil y sigue los siguientes pasos:",
        button:"Contrato Smart de Binance",
        button2:"Comprar",
      },
      whitelist:{
  
      },
      pilares:{
        title: "Crypto para negocios reales",
        text1:"Bienes Raíces",
        text2:"Bot de Trading",
        text3:"Autos",
        text4:"Tecnología",
        text5:"Minería",
      },
      citybot:{
        title:"Citybot",
        text1:"Genera ganancias desde cualquier lugar con nuestro algoritmo.",
        text2:"Fácil, seguro y sin preocupaciones.",
        button:"Invierte Aquí",
      },
      tokenomics:{
        title: "TOKENOMICS | ",
        text1:"CityCoin se conforma de 21,000,000 de tokens emitidos en la red de Binance (BEP20), que serán enfocados en el desarrollo de proyectos elegidos por la misma comunidad, esto involucra; desarrollo de tecnología, minería, proyectos inmobiliarios, etc. Para lograrlo se distribuirá de la siguiente manera:",
        text2:"Preventa de tokens a portadores de NFT.",
        text3:"Venta pública de tokens.",
        text4:"Venta de tokens en DEX.",
        text5:"Stake de tokens.",
        text6:"Bienes raíces",
        text7:"Marketing",
        text8:"Equipo",
        text9:"Legal y Financiero",
        text10:"Equipo de minería",
        text11:"Desarrollo de tecnología",
      },
      roadmap:{
        title:"Roadmap"
      },
      team:{
        title:"Lo que hace fuerte a Citycoin es el equipo que lo respalda",
        text1:"Alberto Horta",
        text2:"Desarrollador y Trader",
        text3:"Raul Romo",
        text4:"Analista de Mercados Bursátiles",
        text5:"Erik Real",
        text6:"Experto en Proyectos y Tecnología",
        text7:"Pedro Sánchez Barrio",
        text8:"Analista de Estrategias de Inversión",
        text9:"Dr. Abraham Jacques",
        text10:"Autor del método 'Gauss-Jacques'",
      },
      founderPass:{
        title:"Preventa ya disponible",
        button:"Comprar Aquí",
        button2:"Ver más",
        text1:"Silver",
        text2:"Palladium",
        text3:"Gold",
        text4:"Incluye",
        text5:"-Acceso a la preventa de la cripto Citycoin",
        text6:"-Acceso a la preventa de la cripto Citycoin",
        text7:"-10% de la Utilidad del los BOTS",
        text8:"-Votos de gobernanza con poder x20",
        text9:"-Acceso a eventos VIP exclusivos para miembros palladium",
        text10:"-Acceso a preventa de lotes, terrenos, inmuebles y automóviles",
        text11:"-Acceso a la preventa de la cripto Citycoin",
        text12:"-Acceso al bot de trading de por vida",
        text13:"-Votos de gobernanza con poder x10",
      }
    }  
}

const i18n = new  VueI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'en',
  allowComposition: true,
  messages // you need to specify that! 
})

var account_1;

// const minABI = MinAbi.abi

// const web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545');
// const contractAddress = '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee';
// const reciever = '0xaC79f551016e82Ba620c3a948Ec948a407Ace10e';
// const main_wallet = '0xaC79f551016e82Ba620c3a948Ec948a407Ace10e';


// if (typeof window.ethereum !== 'undefined') {
//     getAccount();
//     async function getAccount() {
//         const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
//         const account = accounts[0];
//         document.getElementById('wallet').innerHTML=account;
//         if(window.ethereum.chainId !=  '0x61'){
//             window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x61'}]})
//             const contract = new web3.eth.contract(minABI, contractAddress);
//             var  balancesd= parseFloat(await contract.methods.balanceOf(main_wallet).call());
//             balancesd /= Math.pow(10, 18);
//             document.getElementById('balance_wallet').innerHTML=balancesd.toFixed(2)+" BUSD ";
//         }else{
//             const contract = new web3.eth.contract(minABI, contractAddress);
//             var  balancesd= parseFloat(await contract.methods.balanceOf(main_wallet).call());
//             balancesd /= Math.pow(10, 18);
//             document.getElementById('balance_wallet').innerHTML=balancesd.toFixed(2)+" BUSD ";
//         }
//     }
// } else {
//     alert('Please install metamask')
// }

// const ABI = Pago.abi 

// async function pagar(){
//     const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
//     const account = accounts[0];
//     const pay = (document.getElementById("amount").value * Math.pow(10, 18)).toString();
//     let web3;
//     if(window.ethereum){
//         web3 = new Web3(window.ethereum);
//         await ethereum.enable();
//         if(window.ethereum.chainId == '0x61'){
//             const contract = new web3.eth.contract(ABI, contractAddress);
//             const transfer = await contract.methods.transfer(reciever, pay).send({
//                 from: account
//             }).on('receipt',(receipt)=>{alert("Thanks for your payment, in the next 24 hours you will receive your CityCoins to this wallet  "+account+" your tx id is:"+JSON.stringify(receipt.transactionHash)+"")});
//         } else {
//             ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x61'}]})
//         }
//     }
// }


new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')








// Initialize ethers store
// store.dispatch('ethers/init')


