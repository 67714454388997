<template>
    <div id="section_founders" class="d-flex flex-column align-content-center justify-content-center">       
        
        <div class="subsection_one mx-auto d-flex flex-column align-content-center justify-content-center">
            <div class="cont_botones d-flex flex-column align-items-center justify-content-center">
                <h1 class="subtitulo text-center">{{ $t('founderPass.title') }}</h1>
                <div class="cont_boton text-center" data-augmented-ui="tl-2-clip-x border">
                    <a class="boton boton_importante text-center w-100 d-block" href="https://t.me/citycoin_store_bot" target="_blank">{{ $t('founderPass.button') }}</a>
                </div>                          
            </div>
        </div>

        <div class="subsection_two  d-flex flex-wrap align-content-center justify-content-center">   

            <div class="cont_pase mx-auto d-flex flex-sm-row flex-md-column flex-column align-content-center justify-content-center">
                <div class="pase">
                    <video class="w-100" src="../videos/nft_silver.mp4"  muted playsinline autoplay loop></video>
                </div>

                <div class="cont_botones d-flex flex-column align-items-center justify-content-center">
                    <h2 class="titulo text-center">{{ $t('founderPass.text1') }}</h2>
                    <div v-on:click="toggleSilver"  class="cont_boton text-center" data-augmented-ui="tl-2-clip-x border">
                        <h3 class="boton texto text-center">{{ $t('founderPass.button2') }}</h3>
                    </div>    

                    <div v-show="showSilver" class="box justify-content-center align-items-center" data-augmented-ui="bl-2-clip-x border">
                        <span></span>
                        <div class="content">
                            <div class="contenido_box">       
                                <h2 class="subtitulo">{{ $t('founderPass.text4') }}:</h2>                     
                                <p class="texto">{{ $t('founderPass.text5') }}</p>
                            </div>
                        </div>
                    </div>                
                </div>
            </div>

            <div class="cont_pase mx-auto d-flex flex-sm-row flex-md-column flex-column align-content-center justify-content-center">
                <div class="pase">
                    <video class="w-100" src="../videos/nft_palladium.mp4"  muted playsinline autoplay loop></video>
                </div>

                <div class="cont_botones d-flex flex-column align-items-center justify-content-center">
                    <div>
                        <h2 class="titulo text-center">{{ $t('founderPass.text2') }}</h2>
                    </div>
                    
                    <div v-on:click="togglePalladium" class="cont_boton text-center "  data-augmented-ui="tr-2-clip-x tl-2-clip-x border">
                        <h3   class="boton texto text-center">{{ $t('founderPass.button2') }}</h3>
                    </div>     

                    <div v-show="showPalladium" class="box justify-content-center align-items-center" data-augmented-ui="br-2-clip-x bl-2-clip-x border">
                        <span></span>

                        <div class="content">
                            <div class="contenido_box">       
                                <h2 class="subtitulo">{{ $t('founderPass.text4') }}:</h2>    
                                <p class="texto">{{ $t('founderPass.text5') }}</p>     
                                <p class="texto">{{ $t('founderPass.text7') }}</p>
                                <p class="texto">{{ $t('founderPass.text8') }}</p>
                                <p class="texto">{{ $t('founderPass.text9') }}</p>
                                <p class="texto">{{ $t('founderPass.text10') }}</p>
                            </div> 
                        </div>
                    </div>               
                </div>           
                
            </div>

            <div class="cont_pase mx-auto d-flex flex-sm-row flex-md-column flex-column align-content-center justify-content-center">
                <div class="pase">
                    <video class="w-100" src="../videos/nft_gold.mp4"  muted playsinline autoplay loop></video>
                </div>

                <div class="cont_botones d-flex flex-column align-items-center justify-content-center">
                    <h2 class="titulo text-center">{{ $t('founderPass.text3') }}</h2>
                    <div v-on:click="toggleGold"  class="cont_boton text-center" data-augmented-ui="tr-2-clip-x  border">
                        <h3  class="boton texto text-center">{{ $t('founderPass.button2') }}</h3>
                    </div>  

                    <div v-show="showGold" class="box justify-content-center align-items-center" data-augmented-ui="br-2-clip-x border">
                        <span></span>
                        <div class="content">
                            <div class="contenido_box">       
                                <h2 class="subtitulo">{{ $t('founderPass.text4') }}:</h2>            
                                <p class="texto">{{ $t('founderPass.text5') }}</p>           
                                <p class="texto">{{ $t('founderPass.text12') }}</p>
                                <p class="texto">{{ $t('founderPass.text13') }}</p>
                            </div> 
                        </div>
                    </div>                  
                </div>                
            </div>
        </div>      
        
    </div>
</template>

<script>
export default {
    
   

    data() {
        return {
            showSilver: false,
            showPalladium: false,
            showGold: false,
        }
    },
    methods: {
        toggleSilver() {
            this.showSilver = !this.showSilver
            this.showPalladium = false
            this.showGold = false
        },
        togglePalladium() {
            this.showPalladium = !this.showPalladium
             this.showSilver = false
            this.showGold = false
        },
        toggleGold() {
            this.showGold = !this.showGold
            this.showSilver = false
            this.showPalladium = false
        },
       
    },
}
</script>

<style>

#section_founders {
    margin-top: 65px;
    width: 100%;
    background: var(--azul-oscuro);
     padding-bottom:6rem;
}

#section_founders .subsection_one{
    padding-top:4rem;
}

#section_founders .subsection_one .subtitulo{
    color:var(--amarillo) !important;
     font-weight: 700;
    font-style: normal;
    font-size: 2rem;
    }



#section_founders .cont_pase{
    width: 85%;
    margin: 1rem ;
}

#section_founders .cont_pase .pase{
    width: 100%;    
    margin: 1rem auto;
}

#section_founders .cont_pase .pase video{
    width: 100%;
}

#section_founders .cont_pase .titulo{
    font-size: 1rem;
}


#section_founders .subsection_two .box{
    position: relative;
    width: 100%;
    height: auto;
    margin: 1rem auto;
    color: var(--blanco);
    transition: 0.5s;
    display: flex;
}

#section_founders .subsection_two .box:hover{
    transform: translateY(-20px);
}

#section_founders .subsection_two .cont_pase .box::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, var(--negro), var(--azul));
} 

 #section_founders .subsection_two .cont_pase .box::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, var(--negro),var(--amarillo));
    filter: blur(30px);
} 

#section_founders .subsection_two .cont_pase:nth-child(2) .box::before,
#section_founders .subsection_two .cont_pase:nth-child(2) .box::after{
    background: linear-gradient(315deg, var(--amarillo), var(--rojo));
}

#section_founders .subsection_two .cont_pase:nth-child(3) .box::before,
#section_founders .subsection_two .cont_pase:nth-child(3) .box::after{
    background: linear-gradient(315deg, var(--negro), var(--amarillo));
}

#section_founders .subsection_two .box span::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}

#section_founders .subsection_two .box .content{
    position: relative;
    z-index: 10;
    padding: 20px 40px;
    width: 100%;
}

#section_founders .subsection_two .box .content .subtitulo{
    font-size: 2rem;
    color: var(--blanco);
    margin-bottom: 10px;
}

#section_founders .subsection_two .box .content .texto{
    font-size: 1rem;
    color: var(--blanco);
    margin-bottom: 10px;
}






</style>