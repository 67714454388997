<template>
    <section id="info" class="d-flex flex-column px-5">
        <!-- <div id="comprar" class="subsection_two d-flex flex-column mx-auto align-items-center justify-content-center">
                <div class="cont_texto">
                    <h1 class="subtitulo text-center "> <span class="text-uppercase font-weight-bold">¡¡ {{ $t('info.title3') }} !!</span></h1>
                    <h1 class="subtitulo text-center ">{{ $t('info.title2') }} <span class="text-uppercase font-weight-bold"> Citycoins</span></h1>
                </div>
                <div class="cont_texto text-center">
                    <p class="mb-2 texto text-center">{{ $t('info.text13') }}<span class="text-uppercase font-weight-bold"> $0.85 USD </span> </p>
                    <input type="number" class=" text-center input" id="amount" min="1" max="100" name="tentacles" />
                    <p class="mb-2 texto text-center"><span class="text-uppercase font-weight-bold">{{ $t('info.text14') }}</span></p>
                    <p id="wallet" class="mb-2 texto text-center" ></p>
                    <div class="mb-2 sendEthButton cont_boton" data-augmented-ui="br-2-clip-x border" > 
                        <button class="boton boton_importante text-center w-100" type="submit" onclick="pagar()">{{ $t('info.button2') }}</button>
                    </div>
            </div>    
        </div>     -->

        <div class="subsection_two d-flex flex-wrap">
            <div class="cont_izq d-flex flex-column px-lg-5">
                <div class="cont_texto">
                    <h1 class="subtitulo "> {{ $t('info.title2') }}</h1>
                </div>
               
                <div class="cont_proceso d-flex flex-wrap justify-content-center align-items-center mx-center">
                    <div class="cont_texto">
                        <p class="texto ">{{ $t('info.text16') }}</p>
                    </div>
                    <div class="cont_video">
                           <video src="../videos/ctc_pancake_ingles.mp4"  muted playsinline autoplay loop></video>
                    </div>                     
                </div>    
            </div>
        </div>

        <div class="subsection_one d-flex flex-wrap">
            <div class="cont_izq d-flex flex-column px-lg-5">
                <div class="cont_texto">
                    <h1 class="subtitulo "> {{ $t('info.title') }}</h1>
                </div>
                <div class="cont_texto">
                    <p class="texto ">{{ $t('info.text1') }}</p>
                </div>
                <div class="cont_proceso d-flex flex-wrap justify-content-center align-items-center mx-center">
                    <div class="cont_video">
                           <video src="../videos/ctc_wallet_ingles.mp4"  muted playsinline autoplay loop></video>
                    </div>
                    <div class="cont_datos_wallet d-flex flex-column align-items-center justify-content-center">
                        <div class="cont_texto">
                            <p class="texto text-center text-uppercase">{{ $t('info.text2') }} <span class="text-uppercase font-weight-bold">Trustwallet</span></p>
                        </div>    
                        <div class="cont_qr mx-auto">
                            <img src="../assets/images/qr.png" alt="">
                        </div>
                        <div class="cont_texto">
                            <p class="texto text-center"><span class="text-uppercase font-weight-bold">{{ $t('info.text3') }}</span></p>
                            <p class="texto text-center">{{ $t('info.text4') }}</p>
                            <p class="texto text-center"><span class="text-uppercase font-weight-bold">{{ $t('info.text5') }}</span></p>
                            <p class="texto text-center">{{ $t('info.text6') }}</p>                            
                            <p class="texto text-center"><span class="text-uppercase font-weight-bold">{{ $t('info.text7') }}</span></p>
                            <p class="texto text-center">{{ $t('info.text8') }}</p>
                            <p class="texto text-center"><span class="text-uppercase font-weight-bold">{{ $t('info.text9') }}</span></p>
                            <p class="texto text-center">{{ $t('info.text10') }}</p>
                            <p class="texto text-center"><span class="text-uppercase font-weight-bold">{{ $t('info.text11') }}</span></p>
                            <p class="texto text-center">{{ $t('info.text12') }}</p>
                        </div>
                    </div>                    
                </div>                
                <div class="cont_boton " data-augmented-ui="br-2-clip-x border">
                    <a href="https://bscscan.com/address/0xc7D495D8BA942d62e69ba5c617A8575beA35717a" target="_blank" class="boton boton_importante text-center w-100 d-block" >{{ $t('info.button') }}</a>
                </div> 
            </div>
        </div>
        
    </section>
</template>

<style>
    #info{
        width: 100vw;
        background: var(--blanco);
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    #info .subsection_one,
    #info .subsection_two{
        width: 100%;
    }

    #info .subsection_one .cont_izq{
        width: 100%;
    }

     #info .subsection_one .cont_proceso{
        width:100%
    }

     #info .subsection_one .cont_proceso .cont_video,
     #info .subsection_one .cont_proceso .cont_datos_wallet,
     #info .subsection_two .cont_video,
     #info .subsection_two  .cont_texto{
        width: 100%;
        margin: 1rem auto;
     }

      #info .subsection_one .cont_proceso .cont_video video,
      #info .subsection_two .cont_video video{
        width:100%
      }

    #info .subsection_one .cont_proceso .cont_datos_wallet .cont_qr{
        width: 80%;
    }

    #info .subsection_one .cont_proceso .cont_datos_wallet .cont_qr img{
        width: 100%;
    }

     #info .subsection_one .cont_proceso .cont_datos_wallet .cont_texto{
        width: 100%;
     }

    #info .subsection_one .cont_proceso .cont_datos_wallet .cont_texto .texto,
    #info .subsection_one .cont_proceso .cont_datos_wallet .cont_texto .texto span{
        width: 100%;
        font-family: rift,sans-serif !important;
        font-weight: 700;
        font-style: normal;
        margin-bottom: 0;
     }

       #info .subsection_one .cont_proceso .cont_datos_wallet .cont_texto .texto span,
       #info .subsection_two .cont_texto .texto span{
        font-size: 1.5rem;
       }
    
    #info .subsection_one .cont_proceso .cont_datos_wallet .cont_texto .texto,
      #info .subsection_two .cont_texto .texto{
        font-size: 1.3rem;
        /* word-break: break-all; */
    }

    #info .cont_texto .subtitulo{
        text-align: center;
        margin-bottom: 2rem;
        color: var(--azul-oscuro);
    }

    #info .cont_texto .subtitulo span,
    #info .cont_texto .texto span{
        color: var(--rosa);
    }

   
    #info .cont_lottie{
        width: 60%;
    }

    #info .subsection_one  .cont_boton{
        top:0;
     }

     #info .subsection_two{
        width: 100%;
        margin-bottom: 3rem;
     }

      #info .subsection_two .input{
        border: 3px solid  var(--azul-oscuro);
        width: 60% !important;
        padding: 1rem;
      }

      #info .subsection_two .boton{
        width: 100%;
      }
</style>

<script >

    export default {
        methods:{
        
        }
    }
</script>