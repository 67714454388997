<template>
  <div>
    <Banner/>
    <Info/>
    <Whitelist/>
    <Pilares/>
    <Citybot/>
    <Tokenomics/>
    <Roadmap/>
    <Equipo/>
    <Footer/>
  </div>
  
</template>

<script>
  import Banner from '../components/Banner'
  import Info from '../components/Info'
  import Pilares from '../components/Pilares'
  import Tokenomics from '../components/Tokenomics'
  import Roadmap from '../components/Roadmap'
  import Footer from '../components/footer'
  import Equipo from '../components/Equipo'
  import Whitelist from '../components/Whitelist'
   import Citybot from '../components/Citybot'
 

  export default {
    name: 'Home',
    components: {
      Banner,
      Info,
      Pilares,
      Tokenomics,
      Roadmap,
      Footer,
      Equipo,
      Whitelist,
      Citybot
  },
  }
</script>
